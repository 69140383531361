exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-grand-tour-js": () => import("./../../../src/pages/grand-tour.js" /* webpackChunkName: "component---src-pages-grand-tour-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-math-index-js": () => import("./../../../src/pages/math/index.js" /* webpackChunkName: "component---src-pages-math-index-js" */),
  "component---src-pages-new-academic-year-js": () => import("./../../../src/pages/new-academic-year.js" /* webpackChunkName: "component---src-pages-new-academic-year-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-write-to-raise-js": () => import("./../../../src/pages/write-to-raise.js" /* webpackChunkName: "component---src-pages-write-to-raise-js" */)
}

